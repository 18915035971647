<template>
  <div class="project-floor-plan-allocate-agents-add">
    <div class="container fluid">
      <div class="d-flex align-items-center p-2 mb-4">
        <fd-button class="circle mr-2" @click="goToManageAllocationPage">
          <i class="fas fa-chevron-left"></i>
        </fd-button>
        <h2>New Agents Allocation</h2>
      </div>

      <div class="card p-2">
        <h3 class="p-2 mb-2">Available Agents</h3>

        <filter-panel
          v-model="isAvailableAgentFilterActive"
          bordered
          class="filter my-4"
          @clear="clearFilter"
          @filter="filterData"
        >
          <div class="row p-2">
            <fd-input
              v-model="availableAgentsFilter['name[partial]']"
              class="col-12 sm-col-6 md-col-3 px-1 mb-2"
              label="Agent Name"
              name="agentName"
              type="text"
            >
            </fd-input>
            <fd-input
              v-model="availableAgentsFilter['agencyBranch:name[partial]']"
              class="col-12 sm-col-6 md-col-3 px-1 mb-2"
              label="Branch"
              name="branchName"
              type="text"
            >
            </fd-input>
            <fd-input
              v-model="availableAgentsFilter['email']"
              class="col-12 sm-col-6 md-col-3 px-1 mb-2"
              label="Email"
              name="agentEmail"
              type="email"
            >
            </fd-input>
            <fd-input
              v-model="availableAgentsFilter['contact']"
              class="col-12 sm-col-6 md-col-3 px-1 mb-2"
              label="Contact No."
              name="agentContact"
              type="text"
            >
            </fd-input>
          </div>
        </filter-panel>

        <vue-good-table
          ref="availableAgentTable"
          mode="remote"
          @on-page-change="onPageChange"
          @on-per-page-change="onPerPageChange"
          @on-row-click="toggleSelectRow"
          @on-select-all="selectAll"
          :columns="availableAgentsColumn"
          :rows="availableAgentsData"
          :totalRows="availableAgentsPagination.total"
          :pagination-options="{
            enabled: true,
            mode: 'pages',
            perPage: 30,
            perPageDropdown: perPageOptions,
            dropdownAllowAll: false
          }"
          :isLoading="isAvailableAgentsLoading"
          :select-options="{ enabled: true, disableSelectInfo: true }"
        >
          <!-- Loading -->
          <div slot="loadingContent">
            <div class="d-flex justify-content-center">
              <spinner></spinner>
            </div>
          </div>
          <!-- Empty State -->
          <div slot="emptystate">
            <no-data message="There are no agents available to allocate.">
            </no-data>
          </div>
          <!-- Table actions -->
          <div slot="table-actions" class="px-2 py-1">
            <fd-button
              :class="{ active: isAvailableAgentFilterActive }"
              class="btn toggle"
              @click="filterToggle"
            >
              <i class="fas fa-filter"></i>
            </fd-button>
          </div>
          <!-- Table Buttons -->
          <template slot="table-row" slot-scope="props">
            <span v-if="props.column.field == 'actions'">
              <fd-button
                class="bordered danger"
                @click="removeAgent(props.row.id)"
              >
                <i class="fas fa-trash"></i>
              </fd-button>
            </span>
            <span v-else>
              {{ props.formattedRow[props.column.field] }}
            </span>
          </template>
        </vue-good-table>
      </div>

      <collapsible v-model="isToBeAllocatedShown" class="card p-1 mt-2">
        <template #title>
          <div class="d-flex align-items-center p-2">
            <h3>To Be Allocated</h3>
            <chip class="ml-2">{{ toBeAllocatedAgents.length }} selected</chip>
          </div>
        </template>
        <div class="d-flex justify-content-end mb-2">
          <dropdown-button
            v-if="toBeAllocatedAgents.length > 0"
            buttonClass="bordered main ml-2"
          >
            <template #buttonContent>
              <i class="fas fa-ellipsis-v"></i>
            </template>
            <div class="card text-left">
              <fd-list-item
                class="cursor-pointer unselectable"
                @click="setAllPrivate"
              >
                <i class="fas fa-comment-slash mr-1"></i>
                <b>Set all private</b>
                <p class="fg-grey-600">
                  Set these agents to be excluded from the chat rotation.
                </p>
              </fd-list-item>
              <fd-list-item
                class="cursor-pointer unselectable"
                @click="setAllPublic"
              >
                <i class="fas fa-comment mr-1"></i>
                <b>Set all public</b>
                <p class="fg-grey-600">
                  Set these agents to be available in the chat rotation.
                </p>
              </fd-list-item>
              <fd-list-item
                class="cursor-pointer unselectable"
                @click="clearToBeAllocated"
              >
                <i class="fas fa-user-minus mr-1"></i>
                <b>Clear</b>
                <p class="fg-grey-600">
                  Remove all agents to be allocated from the list.
                </p>
              </fd-list-item>
            </div>
          </dropdown-button>
          <fd-button
            v-if="toBeAllocatedAgents.length > 0"
            class="main ml-2"
            :compact="false"
            @click="allocateAgents"
            >Make Allocation</fd-button
          >
        </div>
        <vue-good-table
          :columns="toBeAllocatedAgentsColumn"
          :rows="toBeAllocatedAgents"
          :totalRows="toBeAllocatedAgents.length"
          :pagination-options="{
            enabled: true,
            mode: 'pages',
            perPage: 30,
            perPageDropdown: perPageOptions,
            dropdownAllowAll: false
          }"
          :select-options="{ enabled: false, disableSelectInfo: true }"
        >
          <!-- Table Buttons -->
          <template slot="table-row" slot-scope="props">
            <span v-if="props.column.field == 'action'">
              <fd-button @click="removeToBeAllocatedAgent(props.row.id)">
                <i class="fas fa-trash"></i>
              </fd-button>
            </span>
            <span v-if="props.column.field == 'isPublic'">
              <fd-checkbox
                :value="getIsPublic(props.row.id)"
                @input="(val) => setIsPublic(val, props.row.id)"
              >
              </fd-checkbox>
            </span>
            <span v-else>
              {{ props.formattedRow[props.column.field] }}
            </span>
          </template>
        </vue-good-table>
      </collapsible>
    </div>
  </div>
</template>

<script>
import vueGoodTable from "@/mixins/vue-good-table/mixin";
import { projectAgentAllocation as allocationAPI } from "@/api";
import { ProjectAgentAllocationModel } from "@/models";
import allocateAgentsMixin from "@/mixins/projectAllocateAgents";

export default {
  components: {
    Collapsible: () => import("@/components/GlobalComponents/Collapsible"),
    Chip: () => import("@/components/GlobalComponents/Chip"),
    Spinner: () =>
      import("@/components/GlobalComponents/LoaderComponent/Spinner"),
    DropdownButton: () =>
      import("@/components/GlobalComponents/DropdownButton"),
    FdListItem: () => import("@/components/GlobalComponents/List/FdListItem")
  },
  mixins: [vueGoodTable, allocateAgentsMixin],
  props: {},
  data: function () {
    return {
      isAvailableAgentsLoading: false,
      availableAgentsColumn: [
        {
          label: "Agent",
          field: "name"
        },
        {
          label: "Branch",
          field: "agencyBranch.name"
        },
        {
          label: "Email",
          field: "user.email"
        },
        {
          label: "Contact No.",
          field: "contact"
        }
      ],
      availableAgentsPagination: {
        total: 1
      },
      availableAgentsTableParams: {
        page: 1,
        perPage: 30
      },
      availableAgentsData: [],
      isAvailableAgentFilterActive: false,
      availableAgentsFilter: {
        "name[partial]": "",
        email: "",
        contact: "",
        "agencyBranch:name[partial]": ""
      },

      isToBeAllocatedShown: true,
      toBeAllocatedAgentsColumn: [
        {
          label: "",
          field: "action",
          sortable: false,
          width: "80px"
        },
        {
          label: "Agent",
          field: "name"
        },
        {
          label: "Public",
          field: "isPublic"
        },
        {
          label: "Branch",
          field: "agencyBranch.name"
        },
        {
          label: "Email",
          field: "user.email"
        },
        {
          label: "Contact No.",
          field: "contact"
        }
      ],
      toBeAllocatedAgents: []
    };
  },
  computed: {},
  watch: {},
  created: function () {},
  beforeDestroy: function () {},
  mounted: function () {
    this.init();
  },
  methods: {
    init() {
      this.getAvailableAgents();
    },
    goToManageAllocationPage() {
      this.$router.push({
        name: "ProjectAllocateAgents",
        params: {
          id: this.$route.params.id
        }
      });
    },
    isAgentToBeAllocated(id) {
      return (
        this.toBeAllocatedAgents.find((agent) => {
          return agent.id == id;
        }) ?? false
      );
    },
    getIsPublic(id) {
      return this.toBeAllocatedAgents[
        this.toBeAllocatedAgents.findIndex((agent) => agent.id === id)
      ].canChat;
    },
    setIsPublic(val, id) {
      this.toBeAllocatedAgents[
        this.toBeAllocatedAgents.findIndex((agent) => agent.id === id)
      ].canChat = val;
    },
    setAllPrivate() {
      this.toBeAllocatedAgents.map((agent) => {
        agent.canChat = false;
        return agent;
      });
    },
    setAllPublic() {
      this.toBeAllocatedAgents.map((agent) => {
        agent.canChat = true;
        return agent;
      });
    },
    // ============================= Table Related =============================
    updateParams(newProps) {
      this.availableAgentsTableParams = Object.assign(
        {},
        this.availableAgentsTableParams,
        newProps
      );
    },

    onPageChange(params) {
      this.updateParams({ page: params.currentPage });
      this.getAvailableAgents();
    },

    onPerPageChange(params) {
      this.updateParams({ perPage: params.currentPerPage });
      this.getAvailableAgents();
    },

    toggleSelectRow(params) {
      let agent = params.row;
      let index = this.availableAgentsData.findIndex((i) => i.id === agent.id);

      if (agent.vgtSelected) {
        this.$set(this.availableAgentsData[index], "vgtSelected", true);
        this.addToBeAllocated(agent);
      } else {
        this.$set(this.availableAgentsData[index], "vgtSelected", false);
        this.removeFromAvailableAgents(agent.id);
      }
    },

    selectAll({ selectedRows }) {
      let toBeAllocatedIds = this.toBeAllocatedAgents.map((agent) => agent.id);

      // If all is selected, filter out the previously selected then add new item to the list
      if (selectedRows.length > 0) {
        this.availableAgentsData.forEach((row) => {
          row.vgtSelected = true;
        });

        let agentsToAdd = selectedRows
          .filter((row) => !toBeAllocatedIds.find((id) => id === row.id))
          .map(({ originalIndex, vgt_id, ...item }) => ({
            ...item,
            canChat: true
          }));

        this.toBeAllocatedAgents.push(...agentsToAdd);
      }
      // If deselect, remove all the agent on current page from to be allocated list
      else {
        let agentIdsToRemove = this.availableAgentsData.map(
          (agent) => agent.id
        );

        this.toBeAllocatedAgents = this.toBeAllocatedAgents.filter(
          (agent) => !agentIdsToRemove.find((id) => id === agent.id)
        );
      }
    },

    addToBeAllocated(agent) {
      this.toBeAllocatedAgents.push({ ...agent, canChat: true });
    },

    removeFromAvailableAgents(id) {
      this.toBeAllocatedAgents.splice(
        this.toBeAllocatedAgents.findIndex((agent) => agent.id === id),
        1
      );
    },

    removeToBeAllocatedAgent(id) {
      let index = this.availableAgentsData.findIndex(
        (agent) => agent.id === id
      );
      if (index !== undefined) {
        this.$set(this.availableAgentsData[index], "vgtSelected", false);
      }

      this.removeFromAvailableAgents(id);
    },

    clearToBeAllocated() {
      let idsToBeRemoved = this.toBeAllocatedAgents.map((agent) => agent.id);

      idsToBeRemoved.forEach((id) => {
        this.removeToBeAllocatedAgent(id);
      });
    },
    // ===================== Filter related methods =====================
    filterToggle() {
      this.isAvailableAgentFilterActive = !this.isAvailableAgentFilterActive;
    },
    filterData() {
      this.availableAgentsTableParams.page = 1;
      this.getAvailableAgents();
    },
    clearFilter() {
      this.availableAgentsTableParams.page = 1;
      this.availableAgentsFilter = this._.mapValues(
        this.availableAgentsFilter,
        () => ""
      );
      this.getAvailableAgents();
    },
    // ============================= API Functions =============================
    async getAvailableAgents() {
      try {
        this.isAvailableAgentsLoading = true;
        let filteredParam = this.$cleanQueryParam(this.availableAgentsFilter);

        let data = await allocationAPI.getAvailableAgents(
          this.$route.params.id,
          {
            queries: filteredParam,
            page: this.availableAgentsTableParams.page,
            perPage: this.availableAgentsTableParams.perPage
          }
        );
        this.availableAgentsData = this._.cloneDeep(data.data);
        this.availableAgentsPagination = this._.cloneDeep(data.meta.pagination);

        this.initAvailableAgentVgtSelectedState();

        this.isAvailableAgentsLoading = false;
      } catch (error) {
        this.isAvailableAgentsLoading = false;
        this.$notify({
          group: "alert",
          type: "error",
          title: "Error",
          text: "Failed to get available agents. Please try again later."
        });
      }
    },
    initAvailableAgentVgtSelectedState() {
      // Check if 'to be allocated' is in the list and mark as selected
      this.availableAgentsData = this.availableAgentsData.map((agent) => {
        return { ...agent, vgtSelected: this.isAgentToBeAllocated(agent.id) };
      });
    },
    async allocateAgents() {
      try {
        this.$store.commit("setIsLoading", true);

        let res = await allocationAPI.allocateNewAgents(
          this.$route.params.id,
          ProjectAgentAllocationModel.postPayload(this.toBeAllocatedAgents)
        );

        this.$store.commit("setIsLoading", false);

        this.$notify({
          group: "alert",
          type: "success",
          title: "Agents Allocated",
          text: "Floor plan agents allocation is successfully made."
        });

        this.handlePartialFailedOp(res, "allocated");

        this.goToManageAllocationPage();
      } catch (error) {
        this.$store.commit("setIsLoading", false);
        this.$notify({
          group: "alert",
          type: "error",
          title: "Error",
          text: "An unexpected error occured. Please try again later."
        });
      }
    }
  }
};
</script>

<style lang="scss"></style>
