export default {
    components: {},
    mixins: [],
    props: {},
    data: function () {
        return {};
    },
    computed: {},
    watch: {},
    created: function () {},
    beforeDestroy: function () {},
    mounted: function () {},
    methods: {
        async handlePartialFailedOp(res, operation) {
            let failedAgents = res.fail;
            if (failedAgents.length > 0) {
                await this.$confirm({
                    title: "Partial Operation Failed",
                    message: `
                        <p>These following agency users has failed to be ${operation}:</p>
                        ${this.generateFailedAgentList(failedAgents)}
                        `,
                    confirmText: "OK",
                    showCancel: false
                });
            }
        },

        generateFailedAgentList(failedAgents) {
            let items = "";

            failedAgents.forEach((agent) => {
                items += `<li>${agent}</li>`;
            });

            return `<ul>${items}</ul>`;
        }
    }
};
